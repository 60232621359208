import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const C02Balloon = () => (
  <Layout
    title="CO2 Balloon - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">
            CO<sub>2</sub> Balloon
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">
              CO<sub>2</sub> Balloon
            </h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../marshmallow-tower" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="f126ab13-efc0-4809-a4be-bdaf8f7e7dcf"
                className="de-vid"
              ></Video>
              <Link to="../elephant-toothpaste" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Baking soda and acid reactions in baking make things puff and
              rise. But how could you use this same chemical reaction to blow up
              a balloon?
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              The reaction between baking soda and acid has been known for a
              long time. Its most common use is in baking, where the carbon
              dioxide gas that is produced makes things puff and rise. The first
              known instance of using baking soda for this purpose was from a
              cookbook from 1796!
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Chemistry</li>
              <li>Conservation of Matter</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>Empty Bottle</li>
              <li>Spoon</li>
              <li>Funnel</li>
              <li>Baking Soda (Sodium Bicarbonate)</li>
              <li>Vinegar (Acetic Acid)</li>
              <li>Balloon</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>Pour about 4 tablespoons of vinegar into the bottle</li>
              <li>
                Stretch the end of the balloon over the small end of the funnel,
                and put a generous scoop of sodium bicarbonate into the funnel
                so it falls into the balloon
              </li>
              <li>
                Remove the funnel, and stretch the end of the balloon over the
                top of the bottle with vinegar in it, but make sure the sodium
                bicarbonate stays in the balloon.
              </li>
              <li>
                Make a prediction: What will happen to the balloon when you add
                the bicarbonate soda to the vinegar?
              </li>
              <li>
                Carefully tip the balloon upright, so the baking soda falls out
                of the balloon and into the vinegar.
              </li>
              <li>
                Let the reaction happen and observe what happens to the balloon.
              </li>
              <li>
                With the help of an adult, repeat the experiment in a measuring
                cup without the balloon. Carefully pour the measuring cup
                (without spilling the liquid at the bottom out) over a lit
                candle and see what happens!
              </li>
              <li>
                Be sure to clean up when you are done. The contents of the
                bottle can be poured down the sink, and the balloon can be
                thrown away. Wash anything that needs to be washed, and put
                things away where they belong.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              You should see the baking soda and vinegar mixture start to fizz.
              This is because the acetic acid and sodium bicarbonate are
              reacting together to produce sodium acetate, carbon dioxide gas,
              and water. The carbon dioxide fills up the balloon and inflates
              it, and the other things stay dissolved in the water.
            </p>
            <p>
              Try adding a challenge for yourself by creating your tower for a
              specific purpose. Can you make a tower that holds an egg 6 inches
              off the table? How about a book? How tall can you make a tower
              using only unbroken pieces of spaghetti? Keep experimenting with
              different ways to build.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A: Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        many of them can be either solid or liquid depending on
                        temperature. Matter can be described and classified by
                        its observable properties.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em>
                        <ul>
                          <li>
                            Matter of any type can be subdivided into particles
                            that are too small to see, but even then, the matter
                            still exists and can be detected by other means.
                          </li>
                          <li>
                            A model shows that gases are made from matter
                            particles that are too small to see and are moving
                            freely around in space. This can explain many
                            observations, including the inflation and shape of a
                            balloon and the effects of air on larger particles
                            or objects.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em>
                        <ul>
                          <li>
                            Substances are made from different types of atoms,
                            which combine with one another in various ways.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>MS-PS1-4</em>
                        <ul>
                          <li>
                            Gases and liquids are made of molecules or inert
                            atoms that are moving about relative to each other.
                          </li>
                          <li>
                            In a liquid, the molecules are constantly in contact
                            with others.
                          </li>
                          <li>
                            In a gas, they are widely spaced except when they
                            happen to collide.
                          </li>
                          <li>
                            In a solid, atoms are closely spaced and may vibrate
                            in position but do not change relative locations.
                          </li>
                          <li>
                            The changes of state that occur with variations in
                            temperature or pressure can be described and
                            predicted using these models of matter.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    GPS1.B: Chemical Reactions
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-4.</em> When two or more different substances
                        are mixed. A new substance with different properties may
                        be formed.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-2.</em> Substances react chemically in
                        characteristic ways.
                      </li>
                      <li>
                        <em>MS-PS1-3.</em> In a chemical process, the atoms that
                        make up the original substances are regrouped into
                        different molecules, and these new substances have
                        different properties from those of the
                        reactants.\nMS-PS1-6. Some chemical reactions release
                        energy, others store energy.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="C02Balloon-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Mary Caruso Daily</h3>
                <h4> Advanced Product Development Specialist, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MarshmellowTower-Thumbnail.png" />
              <div className="card__content">
                <h3>Marshmallow Tower</h3>
                <h4>Have you ever wondered how skyscrapers can be so tall?</h4>
                <p>
                  Or how people build bridges to span long distances? Explore
                  engineering techniques to build sturdy structures using only
                  marshmallows and uncooked spaghetti.
                </p>
              </div>
              <Link
                to="../marshmallow-tower"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="ElephantToothpaste-Thumbnail.png" />
              <div className="card__content">
                <h3>Elephant Toothpaste</h3>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she shows you how to make a chemical reaction so big
                  it’s fit for elephants!
                </p>
              </div>
              <Link
                to="../elephant-toothpaste"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="RainbowSnake-Thumbnail.png" />
              <div className="card__content">
                <h3>Rainbow Bubble Snake</h3>
                <h4>
                  Everyone loves bubbles, but have you ever thought about how
                  they form?
                </h4>
                <p>
                  In this video, special guest, Kate the Chemist, shows you the
                  science behind bubbles. With just a few simple materials,
                  you’ll learn how to make a bright and colorful bubble snake
                  using your breath, soap, water and a plastic water bottle.
                </p>
              </div>
              <Link
                to="../rainbow-bubble-snake"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default C02Balloon;
