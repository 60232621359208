import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useImageQuery from '../../hooks/useImageQuery';
import './styles.scss';

const Video = ({
  src,
  guidSrc,
  youTubeID,
  poster,
  autoplay,
  loop,
  title,
  className,
  ...other
}) => {
  const [ready, setReady] = useState(false);
  const [hostname, setHostname] = useState('');
  const { getFinalImagePath, getFinalImagePathByFilename } = useImageQuery();

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          gaId
        }
      }
    }
  `);

  // This will run once after the initial render.
  useEffect(() => {
    setHostname(
      window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    );
    setReady(true);
  }, []);

  const getQueryString = () => {
    let qs = `videoSrc=${src}`;

    if (poster) {
      if (poster.startsWith('https://') || poster.startsWith('http://')) {
        qs += `&posterSrc=${poster}`;
      } else {
        // If we reach here, this must be a local path
        let imagePath = getFinalImagePath(poster);
        if (!imagePath) {
          imagePath = getFinalImagePathByFilename(poster);
        }
        qs += `&posterSrc=${encodeURI(hostname + imagePath)}`;
      }
    }
    if (site.siteMetadata.gaId) {
      qs += `&gaId=${site.siteMetadata.gaId}`;
    }

    return qs;
  };

  return (
    <>
      {ready && (
        <div className={classNames('video', className)} {...other}>
          {youTubeID ? (
            <iframe
              src={`https://www.youtube.com/embed/${youTubeID}`}
              title={title}
              width="1200"
              height="650"
              frameBorder="0"
              allowFullScreen
              className="youtube"
            ></iframe>
          ) : null}
          {guidSrc ? (
            <iframe
              src={
                src
                  ? `https://embed.discoveryeducation.com?${getQueryString()}`
                  : `https://app.discoveryeducation.com/learn/embedded-player/${guidSrc}?utm_source=microsite&utm_medium=embed`
              }
              title={title}
              width="1200"
              height="650"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : null}
        </div>
      )}
    </>
  );
};

Video.defaultProps = {
  src: null,
  poster: null,
  autoplay: false,
  loop: false,
  className: null,
};

Video.propTypes = {
  /** The URL of the video src. */
  src: PropTypes.string,
  /** The GUID for the video. */
  guidSrc: PropTypes.string,
  /** The GUID for the video. */
  youTubeID: PropTypes.string,
  /** The title of the video */
  title: PropTypes.string,
  /** The URL for a poster image */
  poster: PropTypes.string,
  /** Toggle autoplay */
  autoplay: PropTypes.bool,
  /** Toggle autoplay */
  loop: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
};

export default Video;
