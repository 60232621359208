import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const Quicklinks = ({ title, links, className, smoothScroll }) => {

  const getClassnames = () => classNames(
    'quicklinks',
    className
  );

  const clickHandler = (e, target) => {
    if (smoothScroll) {
      const el = document.querySelector(target);
      if (el) {
        e.preventDefault();
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  return (
    <ul className={getClassnames()}>
      {
        title && (
          <li className="quicklinks__title">
            <span>{title}</span>
          </li>
        )
      }
      {links.map(({ target, label, listItemClass, ...rest }) => (
        <li key={`${target}-${label}`} className={classNames('quicklinks__link', listItemClass)}>
          <a href={target} onClick={(e) => clickHandler(e, target)} {...rest}>
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
};

Quicklinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    target: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    listItemClass: PropTypes.string,
  })).isRequired,
  smoothScroll: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
};

Quicklinks.defaultProps = {
  smoothScroll: false,
  title: 'Quick Links',
};

export default Quicklinks;